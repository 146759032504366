import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Container, Row, Col } from 'reactstrap';
import Img from 'gatsby-image';
import ERotate from '../components/icons/animation/eRotation-orig.js';
import ValueLine from '../components/icons/strategy/strategy-find-value';
import IconsMulti from '../components/icons/strategy/iconMultiFamily';
import IconsCali from '../components/icons/strategy/iconCalifornia';
import SideArrow from '../components/icons/arrows/SideArrow';
import { Parallax, ParallaxLayer } from 'react-spring/renderprops-addons';
import BeforeAfterCarousel from '../components/carousel/beforeafter-carousel';

// Previously Strategy Page
const Strategy = ({ data }) => {
    const { page } = data.wpgraphql;
    const [count, setCount] = useState(0);

    let parallax;

    const { beforeImages, afterImages } = data;
    const beforeAndAfterImages = [];
    beforeImages.edges.forEach((item, index) => {
        beforeAndAfterImages.push({
            beforeImage: {
                imageFile: {
                    childImageSharp: {
                        fluid: item.node.childImageSharp.fluid,
                    },
                },
            },
            afterImage: {
                imageFile: {
                    childImageSharp: {
                        fluid: afterImages.edges[index].node.childImageSharp.fluid,
                    },
                },
            },
        });
    });

    return (
        <Parallax ref={(ref) => (parallax = ref)}>
            <Layout props="" classNames={`testClass`}>
                <SEO
                    title={data.wpgraphql.page.title}
                    description={data.wpgraphql.page.acf_page_meta.metadescription}
                />
                <article>
                    <header className="header--fullwidth header--lightbg">
                        {data.wpgraphql.page.featuredImage != null && (
                            <Img
                                className="header__image"
                                fluid={
                                    data.wpgraphql.page.featuredImage.node.imageFile.childImageSharp
                                        .fluid
                                }
                            />
                        )}
                        <Container>
                            <Row>
                                <Col xs={{ offset: 1 }}>
                                    <h1 className="heading1">
                                        Driving Value for our <span>Investors and Partners</span>
                                    </h1>
                                </Col>
                            </Row>
                        </Container>
                        <ERotate classname="logo-e" />
                    </header>

                    <Container>
                        <section className="section__intro ">
                            <Row>
                                <Col
                                    xs={{ size: 12, offset: 1 }}
                                    md={{ size: 8, offset: 5 }}
                                    lg={{ size: 6 }}
                                >
                                    <h2 className="heading2">{page.acf_strategy.heroSubhead}</h2>
                                    <p className="body-copy">{page.acf_strategy.heroText}</p>
                                </Col>
                            </Row>
                        </section>

                        <div className="section__multifamilty-focused">
                            <Row className="no-gutters">
                                <Col
                                    className="pl-0"
                                    xs={{ size: 12, offset: 1 }}
                                    md={{ size: 4, offset: 0 }}
                                    lg={{ size: 3, offset: 1 }}
                                >
                                    <Row className="no-gutters">
                                        <Col sm={{ size: 7 }} md={{ size: 14 }}>
                                            <h2 className="heading2">
                                                {page.acf_strategy.section1.head}
                                            </h2>
                                            <p className="body-copy">
                                                {page.acf_strategy.section1.text}
                                            </p>
                                        </Col>
                                        <Col className="mb-5" sm={{ size: 7 }} md={{ size: 14 }}>
                                            {page.acf_strategy.section1.image != null && (
                                                <Img
                                                    className="w-100 mb-50"
                                                    fixed={
                                                        page.acf_strategy.section1.image.imageFile
                                                            .childImageSharp.fixed
                                                    }
                                                    alt={page.acf_strategy.section1.image.altText}
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col
                                    sm={{ size: 12, offset: 1 }}
                                    md={{ size: 8, offset: 1 }}
                                    lg={{ size: 9, offset: 0 }}
                                >
                                    <Row>
                                        <IconsMulti />
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    <ParallaxLayer
                        offset={-0.04}
                        speed={0.15}
                        className="parallax__arrow-container d-none d-md-block"
                    >
                        <SideArrow className="parallax__arrow-right" />
                    </ParallaxLayer>
                    <Container>
                        <div className="section__cali-centric">
                            <Row>
                                <Col
                                    xs={{ size: 12, offset: 1 }}
                                    md={{ size: 7, offset: 1 }}
                                    lg={{ size: 6, offset: 1 }}
                                >
                                    <h2 className="heading2 text-yellow">
                                        {/* {page.acf_strategy.section2.head} */}
                                        Driven by <br /> Fundamental Analysis
                                    </h2>
                                    <p className="body-copy">{page.acf_strategy.section2.text}</p>
                                </Col>
                            </Row>
                            <IconsCali />
                        </div>

                        <Row>
                            <Col xs={{ size: 14 }} md={{ size: 12, offset: 1 }}>
                                <h2 className="heading2">Case Study: Apex at Sky Valley</h2>
                                <div>
                                    <ul className="bulleted-list">
                                        <li>63.25% IRR</li>
                                        <li>2.23x Equity Multiple</li>
                                        <li>$24,000,000 equity invested</li>
                                        <li>$53,617,315 equity returned</li>
                                        <li>18 Month Hold Period</li>
                                    </ul>
                                    <p>
                                        IDEAL capital acquired Silver Ridge Apartments for
                                        $64,000,000. IDEAL rebranded Silver Ridge to “Apex at Sky
                                        Valley” and completed an extensive $5,000,000 renovation
                                        program. Renovations included transforming the resident
                                        clubhouse from an underutilized afterthought to a modern,
                                        stylish centerpiece for prospective lessees and current
                                        residents alike. The underutilized racquetball court was
                                        repositioned into a calisthenics fitness area, the indoor
                                        pool area was repainted, the fitness room was redesigned
                                        with new equipment, and the indoor lounge areas were
                                        revamped. IDEAL installed a Scandinavian-inspired children’s
                                        tot lot on the north side of the property. IDEAL repainted
                                        the building exteriors and replaced wood rot throughout the
                                        property. For the units, IDEAL added quartz countertops,
                                        gooseneck faucets, installed single basin sinks, new cabinet
                                        faces, new flooring, and replaced lattice balcony fences
                                        with sleek black metal.
                                    </p>
                                    <p>
                                        Through its renovations, IDEAL was able to materially
                                        increase the property rents, resulting in an increased
                                        property value. Investors received a steady monthly
                                        distribution during the hold period. IDEAL sold Apex at Sky
                                        Valley for $101,250,000 a mere 18 months after acquisition,
                                        resulting in invested equity increasing from $24,000,000 to
                                        over $53,600,000.”
                                    </p>
                                </div>
                            </Col>
                            <Col xs={{ size: 14 }} className="mx-0 px-0">
                                <section className="section__investment-slider">
                                    <BeforeAfterCarousel
                                        items={beforeAndAfterImages}
                                        current={count}
                                    />
                                    <div className="carousel__thumbnails">
                                        {beforeAndAfterImages.map((thumb, i) => (
                                            <button
                                                onClick={() => setCount(i)}
                                                className="carousel__thumbnail"
                                            >
                                                <img
                                                    className="w-100"
                                                    alt={`${thumb.afterImage.altText} thumbnail`}
                                                    src={
                                                        thumb.afterImage.imageFile.childImageSharp
                                                            .fluid.src
                                                    }
                                                />
                                            </button>
                                        ))}
                                    </div>
                                </section>
                            </Col>
                        </Row>
                        <div className="section__how-we-find-value">
                            <div className="howvalue-arrow-container"></div>
                            {/* <h2>{page.acf_strategy.section3.head}</h2> */}
                            <Row className="no-gutters">
                                <Col
                                    xs={{ size: 12, offset: 1 }}
                                    sm={{ size: 6, offset: 7 }}
                                    lg={{ size: 4 }}
                                >
                                    <h2 className="heading2">How We Find Value</h2>
                                    <p className="body-copy">{page.acf_strategy.section3.text}</p>
                                </Col>
                            </Row>
                            <Row>
                                <ValueLine />
                            </Row>
                        </div>
                    </Container>

                    <footer className="entry-footer" />
                </article>
            </Layout>
        </Parallax>
    );
};

export default Strategy;

export const query = graphql`
    query {
        beforeImages: allFile(
            filter: {
                name: {
                    in: [
                        "Before1"
                        "Before3"
                        "Before4"
                        "Before5"
                        "Before6"
                        "Before7"
                        "Before8"
                    ]
                }
            }
            sort: { fields: name, order: ASC }
        ) {
            edges {
                node {
                    childImageSharp {
                        fluid(maxWidth: 1200, quality: 90) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
        afterImages: allFile(
            filter: {
                name: { in: ["After1", "After3", "After4", "After5", "After6", "After7", "After8"] }
            }
            sort: { fields: name, order: ASC }
        ) {
            edges {
                node {
                    childImageSharp {
                        fluid(maxWidth: 1200, quality: 90) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
        wpgraphql {
            page(id: "cGFnZTo4") {
                title
                acf_page_meta {
                    metadescription
                }
                featuredImage {
                    node {
                        sourceUrl
                        imageFile {
                            childImageSharp {
                                fluid(maxWidth: 1600, quality: 90) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
                acf_strategy {
                    heroHead
                    heroSubhead
                    heroText
                    section1 {
                        head
                        text
                        bulletsHead
                        bullets {
                            bullet
                        }
                        image {
                            altText
                            sourceUrl
                            imageFile {
                                childImageSharp {
                                    fixed(width: 400, height: 266, quality: 90) {
                                        ...GatsbyImageSharpFixed
                                    }
                                }
                            }
                        }
                    }
                    section2 {
                        head
                        text
                    }
                    section3 {
                        head
                        text
                        bullets {
                            bullet
                        }
                        image {
                            altText
                            sourceUrl
                            imageFile {
                                childImageSharp {
                                    fixed(width: 675, height: 450, quality: 90) {
                                        ...GatsbyImageSharpFixed
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
